import { getErrorToast } from "./toasts"
import { getPdf } from "../../api/node"

const generatePdf = async (data: {title?: string, body: string, setLoading?: (loading: boolean) => void}) => {
    const {title, body, setLoading} = data
    try {
        setLoading?.(true)
        const formattedPdf = await getPdf({title, body})
        const resolvedPdf = formattedPdf.data
        if (resolvedPdf) {
            const element = document.createElement("a")
            element.href = URL.createObjectURL(resolvedPdf)
            element.setAttribute("download", data.title ? data.title + ".pdf" : "")
            element.click()
            element.remove()
        }
    } catch (error) {
        getErrorToast()
    } finally {
        setLoading?.(false)
    }
  
}

export default generatePdf