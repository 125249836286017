import axios from "axios";

const nodeApi = axios.create({
    baseURL: "https://zolomon.ru/api/",
    headers: {
        "Content-Type": "application/json"
    }

})

export const getPdf = (data: {title?: string, body: string}) => nodeApi.post("tools/pdf", {title: data.title, body: data.body, key: "c3a5b1029a8589a0ab7cc11f395117c3"}, {responseType: "blob"})