import { Card } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"

const mockButtons = new Array(4).fill(0).map((item, index) => index)

const SkeletonAccordion: React.FC = props => {
    return <Card className="moduleAccordion_item">
    <Card.Header className="moduleAccordion_itemHeader">
        <Card.Title className="moduleAccordion_itemTitle">
        <Skeleton className="skeleton" height={16} width={350} />
        </Card.Title>
        <div className="moduleAccordion_itemButtons">
            {mockButtons.map(innerIndex =>  <Skeleton key={innerIndex} className="skeleton" height={35} width={35} />)}
        </div>
    </Card.Header>
</Card>
}

export default SkeletonAccordion