import React from "react"
import { useLocation } from "react-router-dom"
import ModuleInCreation from "../constructor/modules/ModuleInCreation"
import SplashScreen from "../constructor/helpers/SplashScreen"

const ModuleSchemes = React.lazy(() => import("../constructor/modules/ModuleSchemes"))

const Module: React.FC<{
    type: string 
}> = ({type}) => {
    switch (type) {
        case "databaseSchemes":
            return <ModuleSchemes />
        default:
            return <ModuleInCreation type={type} settings={[]} components={[]} size={4}/>
    }
}

const StaticPage: React.FC = () => {
    const { pathname } = useLocation()
    const splitedUrl = pathname.split("/")
    const type = splitedUrl[splitedUrl.length - 1]
    return <div className="staticPage">
        <React.Suspense fallback={<SplashScreen active />}>
        <Module type={type} />
        </React.Suspense>
     
    </div>
}

export default StaticPage