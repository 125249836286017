type TincomingOptions = {
    printMode?: "iframe" | "popup",
    pageTitle?: string,
    templateString?: string,
    popupProperties?: string,
    stylesheets?: string | Array<string>,
    styles?: string | Array<string>,
}

type Toptions = {
    printMode: "iframe" | "popup";
    pageTitle: string;
    templateString: string;
    popupProperties: string;
    stylesheets: string | string[] | null;
    styles: string | string[] | null;
}

const _getBaseHref = () => {
    const port = window.location.port ? ':' + window.location.port : '';
    return window.location.protocol + '//' + window.location.hostname + port + window.location.pathname;
}

const _callPrint = (printWindow: (Window | HTMLIFrameElement) & {printPage?: () => {}}) => {
    if (printWindow && printWindow.printPage) {
        printWindow.printPage();
    } else {
        setTimeout(function () {
            _callPrint(printWindow);
        }, 50);
    }
}

const _getMarkup = (elementHtml: string, options: Toptions) => {
    let template = options.templateString,
        templateRegex = new RegExp(/{{\s*printBody\s*}}/gi),
        stylesheets,
        styles,
        html = [];

    if (template && templateRegex.test(template)) {
        elementHtml = template.replace(templateRegex, elementHtml);
    }

    html.push('<html><head><title>' + (options.pageTitle || '') + '</title>');

    // If stylesheet URL's or list of stylesheet URL's are specified, override page stylesheets

    if (options.stylesheets) {
        stylesheets = Array.isArray(options.stylesheets) ? options.stylesheets : [options.stylesheets];
    } else {
        stylesheets = Array.prototype.slice.call(document.getElementsByTagName('link')).map(function (link) {
            return link.href;
        });
    }

    stylesheets.forEach(function (href) {
        html.push('<link rel="stylesheet" href="' + href + '">');
    });

    // If inline styles or list of inline styles are specified, override inline styles
    if (options.styles) {
        styles = Array.isArray(options.styles) ? options.styles : [options.styles];
    } else {
        styles = Array.prototype.slice.call(document.getElementsByTagName('style')).map(function (style) {
            return style.innerHTML;
        });
    }

    styles.forEach(function (style) {
        html.push('<style type="text/css">' + style + '</style>');
    });

    // Ensure that relative links work
    html.push('<base href="' + _getBaseHref() + '" />');
    html.push('</head><body class="pe-body">');
    html.push(elementHtml);
    html.push('<script type="text/javascript">function printPage(){focus();print();' + (options.printMode == 'popup' ? 'close();' : '') + '}</script>');
    html.push('<script type="text/javascript">window.onafterprint = () => window.frameElement.remove()</script>');
    html.push('</body></html>');

    return html.join('');
}

const _print = (html: string, options?: TincomingOptions) => {

    const resolvedOptions: Toptions = {
        printMode: options?.printMode || 'iframe',
        pageTitle: options?.pageTitle || '',
        templateString: options?.templateString || '',
        popupProperties: options?.popupProperties || '',
        stylesheets: options?.stylesheets || null,
        styles: options?.styles || null
    };

    // Get markup to be printed
    let markup = _getMarkup(html, resolvedOptions),
        printWindow: Window | HTMLIFrameElement,
        printDocument: Document | null,
        printElementID: string;


    if (resolvedOptions.printMode === 'popup') {
        printWindow = window.open('about:blank', 'printElementWindow', resolvedOptions.popupProperties) as Window;
        printDocument = printWindow.document;
    } else {
        //The random ID is to overcome a safari bug
        // http://www.cjboco.com.sharedcopy.com/post.cfm/442dc92cd1c0ca10a5c35210b8166882.html
        printElementID = `printElement_${Math.round(Math.random() * 99999)}`;

        let printIframe = document.createElement('iframe');
        printIframe.setAttribute('id', printElementID);
        printIframe.setAttribute('src', 'about:blank');
        printIframe.setAttribute('frameBorder', '0');
        printIframe.setAttribute('scrolling', 'no');
        printIframe.setAttribute('style', 'position:fixed;bottom:100%;right:100%;');

        document.body.appendChild(printIframe);

        printDocument = printIframe.contentWindow?.document || printIframe.contentDocument;

        printWindow = printIframe.contentWindow || printIframe;
    }

    printDocument?.open();

        // SetTimeout fixes Issue #9 (iframe printMode does not work in firefox)
        setTimeout(function () {
            printDocument?.write(markup);
            printDocument?.close();
        });

    _callPrint(printWindow);

}

const print = (element: HTMLElement | string, options?: TincomingOptions) => {
    const resolvedHtml = element instanceof HTMLElement ? element.outerHTML : element
    _print(resolvedHtml, options);

}


export default print