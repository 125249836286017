import React from "react"

type TSplashScreen = {
  active: boolean,
  className?: string,
}

export const SplashSpinner: React.FC = props => {
  return <svg className="splash-spinner" viewBox="0 0 50 50">
    <circle className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
    ></circle>
  </svg>
}

const SplashScreen: React.FC<TSplashScreen> = ({ active, className = "" }) => {
  return <div className={`data-loader${active ? "" : " hidden"} ${className}`}>
    <SplashSpinner />
  </div>
}

export default SplashScreen